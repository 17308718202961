import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  plans: [],
  lmp : "",
};
const plansSlice = createSlice({
  name: "plans",
  initialState: initialState,
  reducers: {
    reset :() => initialState,
    savePlans(state, action) {
      state.plans = action.payload;
    },
    saveLmp(state,action){
      state.lmp = action.payload;
    },
    applyDiscountOnSelectedPlan(state,actions){
     const {selectedPlanId, discount} = actions.payload
     for (let i = 0; i < state.plans.length; i++) {
      if (state.plans[i]._id === selectedPlanId) {
        // console.log("before : "+ state.plans[i].price)
        state.plans[i].price = state.plans[i].price - discount; 
        // console.log("after : "+ state.plans[i].price)
        console.log(selectedPlanId + " doen! "+discount)
        break; 
      }
    }
    }
  },
});

export const PlansActions = plansSlice.actions;
export default plansSlice;
