import React, { useState } from 'react';
import { FaClinicMedical, FaHome } from 'react-icons/fa'; // Using react-icons for the home icon
import styles from './OfflineConsultation.module.css';

const OfflineConsultation = () => {
  const [selectedLocation, setSelectedLocation] = useState('jayanagar'); // State for selected location

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  return (
    <div className={styles.consultationContainer}>
      <h2 className={styles.consultationHeading}>Consultation method</h2>
      

        <div className={styles.titleContainer}>
          <FaClinicMedical className={styles.icon} />
          <span>
            <span className={styles.offlineText}>Offline</span>
            <span className={styles.consultationText}> consultation:</span>
          </span>
        </div>
        
        <div className={styles.locationOptions}>
          <label className={styles.option}>
            <input
              type="radio"
              name="location"
              value="jayanagar"
              checked={selectedLocation === 'jayanagar'}
              onChange={handleLocationChange}
            />
            <span className={styles.optionText}>Jayanagar</span>
          </label>
        </div>
    </div>
  );
};

export default OfflineConsultation;
