import React from 'react';
import styles from './TeamOfExperts.module.css';

const TeamOfExperts = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Our Team of Experts</h2>
      
      <div className={styles.cardsContainer}>
        {/* Card 1 */}
        <div className={styles.card}>
          <img
            src="https://cdn.imumz.com/pages.imumz/drSunil.png"
            alt="Dr. Sunil Eshwar"
            className={styles.image}
          />
          <h3 className={styles.name}>Dr. Sunil Eshwar</h3>
          <p className={styles.titleText}>MBBS, MD - Obstetrics & Gynaecology</p>
        </div>

        {/* Card 2 */}
        <div className={styles.card}>
          <img
            src="https://cdn.imumz.com/pages.imumz/drNayak.png"
            alt="Dr. Smriti Nayak"
            className={styles.image}
          />
          <h3 className={styles.name}>Dr. Smriti Nayak</h3>
          <p className={styles.titleText}>Asst. Medical Director & Fertility Specialist</p>
        </div>

        {/* Card 3 */}
        <div className={styles.card}>
          <img
            src="https://cdn.imumz.com/pages.imumz/DrRaksha.png"
            alt="Dr. Raksha"
            className={styles.image}
          />
          <h3 className={styles.name}>Dr. Raksha</h3>
          <p className={styles.titleText}>BAMS, MS-Ayu (OBGYN), YIC</p>
        </div>
      </div>
    </div>
  );
};

export default TeamOfExperts;
