import React, { Suspense } from "react";
import BookingConfirmation from "../components/BookingConfirmation/BookingConfirmation";
import store from "../store/store";
import { defer, json, Await, useLoaderData } from "react-router";
import HourglassComponent from "../components/UI/HourglassComponent/HourglassComponent";
import { reqSlotBookingRetool } from "../lib/api";

const BookingConfirmationPage = () => {
    const { events } = useLoaderData();
  return (
    <Suspense fallback={<HourglassComponent />}>
      <Await resolve={events} errorElement={ <BookingConfirmation success={false} /> }>
        {(_loadedEvents) => <BookingConfirmation />}
      </Await>
    </Suspense>
  );
};

export default BookingConfirmationPage;



export const loader = async ({ request }) => {
console.log("somehing")
  return defer({
    events: reqSlotBookingRetool(),
  });
};
