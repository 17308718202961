import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaCalendarAlt } from "react-icons/fa";
import {
  format,
  addDays,
  setHours,
  setMinutes,
  isAfter,
  addHours,
} from "date-fns";
import styles from "./Consultation.module.css";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { goBack } from "../../../utils/ui-utils";
import { useNavigate } from "react-router";
import { ROUTE_BOOKING_CONFIRMATION } from "../../../utils/constants/global-constants";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../store/slices/user-slice";
import { UiActions } from "../../../store/slices/ui-slice";

// Generate consultation data with time slots and future dates
function generateConsultationData(daysCount = 3) {
  const timeSlots = [
    { hour: 10, minute: 30 },
    { hour: 11, minute: 0 },
    { hour: 11, minute: 30 },
    { hour: 12, minute: 0 },
    { hour: 12, minute: 30 },
    { hour: 13, minute: 0 },
    { hour: 14, minute: 30 },
    { hour: 16, minute: 0 },
    { hour: 16, minute: 30 },
    { hour: 17, minute: 0 },
    { hour: 17, minute: 30 },
    { hour: 18, minute: 0 },
    { hour: 18, minute: 30 },
    { hour: 19, minute: 0 },
    { hour: 19, minute: 30 },
    { hour: 20, minute: 0 },
  ];

  const consultationData = { availableSlots: [], slots: {} };
  const twoHoursLater = addHours(new Date(), 2);

  for (let i = 0; i < daysCount; i++) {
    const date = addDays(new Date(), i);
    const dateISO = format(date, "yyyy-MM-dd");

    const slotsForDay = timeSlots
      .map(({ hour, minute }) => setHours(setMinutes(date, minute), hour))
      .filter((time) => i !== 0 || isAfter(time, twoHoursLater))
      .map((time) => time.toISOString());

    consultationData.slots[dateISO] = slotsForDay;
    consultationData.availableSlots.push({
      date: dateISO,
      slots: slotsForDay.length,
    });
  }
  return consultationData;
}

function categorizeSlotsByTimeOfDay(slots) {
  const morningSlots = [];
  const afternoonSlots = [];
  const eveningSlots = [];

  slots && slots.forEach((slot) => {
    const hour = new Date(slot).getHours();
    if (hour < 12) morningSlots.push(slot);
    else if (hour < 17) afternoonSlots.push(slot);
    else eveningSlots.push(slot);
  });

  return {
    morning: morningSlots,
    afternoon: afternoonSlots,
    evening: eveningSlots,
  };
}

const consultationData = generateConsultationData();

const Consultation = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const moveAhead = useSelector((state) => state.ui.moveAhead);
  const saveConsultationTime = useSelector((state) => state.user.extras.consultationTime);

  // Set the default selected date to the first available date on mount
  useEffect(() => {
    if (consultationData.availableSlots.length > 0) {
      setSelectedDate(consultationData.availableSlots[0].date);
    }
  }, []);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSelectedTime(null); // Reset selected time when changing date
  };

  const handleTimeSelect = (time) => setSelectedTime(time);

  const handleNextClick = () => {
    if (selectedDate && selectedTime) {
      const bookingDetails = {
        consultationTime: selectedTime,
        displayDate: format(new Date(selectedDate), "dd/MM/yyyy"),
        displayTime: format(new Date(selectedTime), "hh:mm a"),
      };
      dispatch(userActions.appendPropsInExtras(bookingDetails));
      dispatch(UiActions.setMoveAhead(true));
      console.log("Booking Details:", bookingDetails);
    }
  };

  const slotsForSelectedDate = selectedDate
    ? consultationData.slots[selectedDate]
    : [];
  const categorizedSlots = categorizeSlotsByTimeOfDay(slotsForSelectedDate);

  useEffect(() => {
    if (moveAhead && saveConsultationTime) {
      navigate(`/${ROUTE_BOOKING_CONFIRMATION}`);
      dispatch(UiActions.setMoveAhead(false));
    }
  }, [moveAhead, navigate, dispatch, saveConsultationTime]);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.content}>
        <div className={styles.header}>
          <button className={styles.backButton} onClick={goBack}>
            <FaArrowLeft className={styles.backIcon} /> Back
          </button>
        </div>

        <div className={styles.slotSelection}>
          <h2 className={styles.sectionTitle}>
            <FaCalendarAlt className={styles.icon} /> Select slots
          </h2>
          <div className={styles.dateContainer}>
            {consultationData.availableSlots.map((slot, index) => (
              <div
                key={index}
                className={`${styles.date} ${
                  slot.date === selectedDate ? styles.selected : ""
                }`}
                onClick={() => handleDateSelect(slot.date)}
              >
                <p className={styles.dateText}>
                  {format(new Date(slot.date), "EEE")}
                </p>
                <p className={styles.dateNumber}>
                  {format(new Date(slot.date), "dd")}
                </p>
                <p className={styles.slotsAvailable}>
                  {slot.slots} Slot{slot.slots > 1 && "s"}
                </p>
              </div>
            ))}
          </div>
          <div className={styles.notice}>
            <IoIosInformationCircleOutline /> <p>Slots open 3 days earlier</p>
          </div>
        </div>

        {categorizedSlots.morning.length > 0 && (
          <TimeSection
            title="Morning"
            slots={categorizedSlots.morning}
            selectedTime={selectedTime}
            handleTimeSelect={handleTimeSelect}
          />
        )}
        {categorizedSlots.afternoon.length > 0 && (
          <TimeSection
            title="Afternoon"
            slots={categorizedSlots.afternoon}
            selectedTime={selectedTime}
            handleTimeSelect={handleTimeSelect}
          />
        )}
        {categorizedSlots.evening.length > 0 && (
          <TimeSection
            title="Evening"
            slots={categorizedSlots.evening}
            selectedTime={selectedTime}
            handleTimeSelect={handleTimeSelect}
          />
        )}
        {slotsForSelectedDate.length === 0 && (
          <div className={styles.caption}>
            <p>No slots available for selected date.</p>
          </div>
        )}
      </div>

      <div className={styles.footer}>
        <button
          className={styles.nextButton}
          onClick={handleNextClick}
          disabled={!selectedDate || !selectedTime} // Disable button if date or time is not selected
        >
          Next
        </button>
      </div>
    </div>
  );
};

const TimeSection = ({ title, slots, selectedTime, handleTimeSelect }) => (
  <div className={styles.timeSlotsContainer}>
    <h3 className={styles.timeSlotTitle}>{title}</h3>
    <div className={styles.timeSlots}>
      {slots.map((time, index) => (
        <button
          key={index}
          className={`${styles.timeSlot} ${
            time === selectedTime ? styles.selected : ""
          }`}
          onClick={() => handleTimeSelect(time)}
        >
          {format(new Date(time), "hh:mm a")}
        </button>
      ))}
    </div>
  </div>
);

export default Consultation;
