import React from 'react';
import styles from './GoogleReviews.module.css';
import { FaStar, FaStarHalfAlt, FaGoogle, FaCheckCircle } from 'react-icons/fa';

const GoogleReviews = () => {
  // Sample data for reviews
  const reviews = [
    {
      id: 1,
      name: 'Tulasappa K',
      location: 'Jayanagar, Bengaluru',
      rating: 4.5,
      time: '3 hours ago',
      content:
        'Very good consultation and nice treatment. Allo Health has the best sexologist. Thanks to Dr. Raj!',
    },
    {
      id: 2,
      name: 'John D',
      location: 'Electronic City, Bengaluru',
      rating: 5,
      time: '1 day ago',
      content: 'STD testing was fast and professional. The doctor made me feel very comfortable!',
    },
    {
      id: 3,
      name: 'Anita M',
      location: 'Whitefield, Bengaluru',
      rating: 4,
      time: '2 days ago',
      content: 'Amazing support and excellent doctors. Highly recommend this clinic for any fertility issues.',
    },
  ];

  // Function to generate a color based on the first letter of the name
  const getAvatarColor = (name) => {
    const colors = [
        '#FFB6C1', '#FF69B4', '#FF8C00', '#FFA500', '#FFD700', // Pink and Orange shades
        '#ADFF2F', '#32CD32', '#20B2AA', '#1E90FF', '#9370DB', // Green, Teal, Blue, Purple shades
        '#DC143C', '#FF4500', '#FF6347', '#FF7F50', '#FFDAB9', // Red and Coral shades
        '#8A2BE2', '#9400D3', '#9932CC', '#BA55D3', '#DA70D6', // Violet and Purple shades
        '#00CED1', '#48D1CC', '#40E0D0', '#00FA9A', '#00FF7F', // Aqua and Mint shades
        '#7FFFD4', '#66CDAA', '#3CB371', '#2E8B57', '#228B22', // Sea Green and Forest Green shades
        '#6B8E23', '#BDB76B', '#FFD700', '#FFE4B5', '#FFDAB9', // Olive, Gold, Peach shades
      ];
      
    const firstLetter = name[0].toUpperCase();
    const index = (firstLetter.charCodeAt(0) - 65) % colors.length;
    return colors[index];
  };

  // Helper function to render stars based on rating
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating); // Number of full stars
    const hasHalfStar = rating % 1 !== 0; // Check if there is a half star

    return (
      <>
        {[...Array(fullStars)].map((_, index) => (
          <FaStar key={index} color="#f4c150" />
        ))}
        {hasHalfStar && <FaStarHalfAlt color="#f4c150" />}
      </>
    );
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        <span className={styles.googleLogo}>Google</span> Reviews
      </h2>
      <div className={styles.rating}>
        <span className={styles.ratingScore}>4.9</span>
        <div className={styles.stars}>
          {renderStars(4.9)}
        </div>
        <span className={styles.reviewCount}>100+ reviews</span>
      </div>
      <div className={styles.carousel}>
        {reviews.map((review) => (
          <div key={review.id} className={styles.reviewCard}>
            <div className={styles.header}>
              <div
                className={styles.avatar}
                style={{ backgroundColor: getAvatarColor(review.name) }}
              >
                {review.name[0]}
              </div>
              <div>
                <span className={styles.reviewerName}>{review.name}</span> <FaCheckCircle className={styles.verifiedIcon} />
                <p className={styles.location}>{review.location}</p>
              </div>
            </div>
            <div className={styles.reviewRating}>
              {renderStars(review.rating)}
              <span className={styles.reviewTime}>{review.time}</span>
            </div>
            <p className={styles.reviewContent}>{review.content}</p>
            <div className={styles.googleBadge}>
              <FaGoogle /> Posted on Google
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GoogleReviews;
