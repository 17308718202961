import React from 'react'
import FertilityClinic from '../components/FertilityClinic/FertilityClinic'

const FertilityClinicPage = () => {
  return (
    <div>
        <FertilityClinic/>
    </div>
  )
}

export default FertilityClinicPage