import React from 'react';
import styles from './HowWeWork.module.css';

const HowWeWork = () => {
  return (
    <div className={styles.container} style={{ minHeight: '90vh' }}>
      <h2 className={styles.title}>How we work</h2>
      
      <div className={styles.cardsContainer}>
        {/* Card 1 */}
        <div className={styles.card}>
          <p className={styles.step}>Step 1</p>
          <img
            src="https://cdn.imumz.com/pages.imumz/step1-fc.png"
            alt="Step 1"
            className={styles.image}
          />
          <h3 className={styles.cardTitle}>Get a Comprehensive Fertility Evaluation done</h3>
          <p className={styles.cardText}>
            Let our friendly fertility expert & Ayurvedic doctor evaluate you separately.
          </p>
        </div>

        {/* Card 2 */}
        <div className={styles.card}>
          <p className={styles.step}>Step 2</p>
          <img
            src="https://cdn.imumz.com/pages.imumz/step-2-fc.png"
            alt="Step 2"
            className={styles.image}
          />
          <h3 className={styles.cardTitle}>Understand all your options to start your family</h3>
          <p className={styles.cardText}>
            Get a joint opinion of our experts on what options are the best options for you. We are
            bringing the best of all the worlds for you to get right results.
          </p>
        </div>

        {/* Card 3 */}
        <div className={styles.card}>
          <p className={styles.step}>Step 3</p>
          <img
            src="https://cdn.imumz.com/pages.imumz/step-3-fc.png"
            alt="Step 3"
            className={styles.image}
          />
          <h3 className={styles.cardTitle}>Make an informed decision</h3>
          <p className={styles.cardText}>
            Understand all your options, discuss your doubts and take a confident decision. Guidance
            is ours, decision is yours.
          </p>
        </div>

        {/* Card 4 */}
        <div className={styles.card}>
          <p className={styles.step}>Step 4</p>
          <img
            src="https://cdn.imumz.com/pages.imumz/step-4-fc.png"
            alt="Step 4"
            className={styles.image}
          />
          <h3 className={styles.cardTitle}>Start your fertility journey</h3>
          <p className={styles.cardText}>
            Once you choose the right course of action, let us guide you to achieve your goals by
            constant monitoring.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowWeWork;
