import React from 'react';
import styles from './FeaturedIn.module.css';

const logos = [
    'https://cdn.imumz.com/pages.imumz/dd-ft.png',
    'https://cdn.imumz.com/pages.imumz/dna-ft.png',
    'https://cdn.imumz.com/pages.imumz/fem-ft.png',
    'https://cdn.imumz.com/pages.imumz/tet-ft.png',
    'https://cdn.imumz.com/pages.imumz/the-hindus.png',
    'https://cdn.imumz.com/pages.imumz/tie-ft.png',
    'https://cdn.imumz.com/pages.imumz/toi-ft.png',
    'https://cdn.imumz.com/pages.imumz/ys-ft.png',
    'https://cdn.imumz.com/pages.imumz/zn-ft.png',
];

const FeaturedMarquee = () => {
  return (
    <div className={styles.featuredContainer}>
      <h2 className={styles.title}>Featured in</h2>
      <div className={styles.marqueeWrapper}>
        <div className={styles.marquee}>
          {logos.map((logo, index) => (
            <img key={index} src={logo} alt="Featured logo" className={styles.logo} />
          ))}
          {/* Duplicate content for seamless scrolling */}
          {logos.map((logo, index) => (
            <img key={`${index}-duplicate`} src={logo} alt="Featured logo duplicate" className={styles.logo} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedMarquee;