import React, { useState } from "react";
import { FaArrowLeft, FaCalendarAlt } from "react-icons/fa";
import { format } from "date-fns";
import styles from "./Consultation.module.css";
import { IoIosInformationCircleOutline } from "react-icons/io";

function categorizeSlotsByTimeOfDay(slots) {
  const morningSlots = [];
  const afternoonSlots = [];
  const eveningSlots = [];

  if (slots)
    slots.forEach((slot) => {
      const hour = new Date(slot).getHours();

      if (hour < 12) {
        morningSlots.push(slot);
      } else if (hour >= 12 && hour < 17) {
        afternoonSlots.push(slot);
      } else {
        eveningSlots.push(slot);
      }
    });

  return {
    morning: morningSlots,
    afternoon: afternoonSlots,
    evening: eveningSlots,
  };
}

const doctorData = {
  name: "Dr. Niva Jacob",
  experience: "4 yr exp • MBBS, DNB (Psychiatry)",
  location: "Indiranagar",
  timeRange: "5 PM - 8 PM",
  availableSlots: [
    { date: "2024-03-12T17:00:00.000Z", slots: 10 },
    { date: "2024-03-13T17:00:00.000Z", slots: 1 },
    { date: "2024-03-14T17:00:00.000Z", slots: 14 },
    { date: "2024-03-15T17:00:00.000Z", slots: 18 },
    { date: "2024-03-16T17:00:00.000Z", slots: 19 },
  ],
  slots: {
    "2024-03-12": [
      "2024-03-12T09:00:00.000Z",
      "2024-03-12T15:30:00.000Z",
      "2024-03-12T20:00:00.000Z",
    ],
    "2024-03-13": ["2024-03-13T10:00:00.000Z", "2024-03-13T13:00:00.000Z"],
    "2024-03-14": ["2024-03-14T17:00:00.000Z"],
  },
};

const Consultation = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSelectedTime(null); // Reset selected time when changing date
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const handleNextClick = () => {
    if (selectedDate && selectedTime) {
      const consultationTime = `${selectedDate}T${selectedTime}`;
      const bookingDetails = {
        doctor: doctorData,
        consultationTime,
        displayDate: format(new Date(selectedDate), "dd/MM/yyyy"),
        displayTime: format(new Date(selectedTime), "HH:mm"),
      };
      console.log("Booking Details:", bookingDetails);
    }
  };

  const slotsForSelectedDate = selectedDate
    ? doctorData.slots[selectedDate.split("T")[0]]
    : [];
  const categorizedSlots = categorizeSlotsByTimeOfDay(slotsForSelectedDate);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.content}>
        <div className={styles.header}>
          <button className={styles.backButton}>
            <FaArrowLeft className={styles.backIcon} /> Back
          </button>
          {/* <h1 className={styles.title}>Schedule Consultation</h1> */}
        </div>

        <div className={styles.doctorInfo}>
          <p className={styles.timeRange}>{doctorData.timeRange}</p>
          <div className={styles.doctorDetails}>
            <img
              src="https://cdn.imumz.com/pages.imumz/drSunil.png"
              alt="Doctor"
              className={styles.doctorImage}
            />
            <div className={styles.doctorTextDetails}>
              <p className={styles.doctorName}>{doctorData.name}</p>
              <p className={styles.doctorDescription}>
                {doctorData.experience}
              </p>
              <p className={styles.doctorLocation}>{doctorData.location}</p>
            </div>
          </div>
        </div>

        <div className={styles.slotSelection}>
          <h2 className={styles.sectionTitle}>
            <FaCalendarAlt className={styles.icon} /> Select slots
          </h2>
          <div className={styles.dateContainer}>
            {doctorData.availableSlots.map((slot, index) => (
              <div
                key={index}
                className={`${styles.date} ${
                  slot.date === selectedDate ? styles.selected : ""
                }`}
                onClick={() => handleDateSelect(slot.date)}
              >
                <p className={styles.dateText}>
                  {format(new Date(slot.date), "EEE")}
                </p>
                <p className={styles.dateNumber}>
                  {format(new Date(slot.date), "dd")}
                </p>
                <p className={styles.slotsAvailable}>
                  {slot.slots} Slot{slot.slots > 1 && "s"}
                </p>
              </div>
            ))}
          </div>
          <div className={styles.notice}>
            <IoIosInformationCircleOutline /> <p>Slots open 5 days earlier</p>
          </div>
        </div>

        {categorizedSlots.morning.length > 0 && (
          <TimeSection
            title="Morning"
            slots={categorizedSlots.morning}
            selectedTime={selectedTime}
            handleTimeSelect={handleTimeSelect}
          />
        )}

        {categorizedSlots.afternoon.length > 0 && (
          <TimeSection
            title="Afternoon"
            slots={categorizedSlots.afternoon}
            selectedTime={selectedTime}
            handleTimeSelect={handleTimeSelect}
          />
        )}

        {categorizedSlots.evening.length > 0 && (
          <TimeSection
            title="Evening"
            slots={categorizedSlots.evening}
            selectedTime={selectedTime}
            handleTimeSelect={handleTimeSelect}
          />
        )}

        {categorizedSlots.evening.length === 0 &&
          categorizedSlots.afternoon.length === 0 &&
          categorizedSlots.morning.length === 0 && 
          <div className={styles.caption}>
          <p>No slots available for selected date.</p>
          </div>}
      </div>

      <div className={styles.footer}>
        <button className={styles.nextButton} onClick={handleNextClick}>
          Next
        </button>
      </div>
    </div>
  );
};

const TimeSection = ({ title, slots, selectedTime, handleTimeSelect }) => (
  <div className={styles.timeSlotsContainer}>
    <h3 className={styles.timeSlotTitle}>{title}</h3>
    <div className={styles.timeSlots}>
      {slots.map((time, index) => (
        <button
          key={index}
          className={`${styles.timeSlot} ${
            time === selectedTime ? styles.selected : ""
          }`}
          onClick={() => handleTimeSelect(time)}
        >
          {format(new Date(time), "hh:mm a")}
        </button>
      ))}
    </div>
  </div>
);

export default Consultation;
