import React, { useState } from "react";
import styles from "./ConsultationForm.module.css";
import { FaWhatsapp } from "react-icons/fa"; // Importing WhatsApp icon from react-icons
import OfflineConsultation from "./OfflineConsultation";
import BookingSection from "./BookingSection";
import Spacer from "../util/Spacer";

const ConsultationForm = () => {
  const [whatsappNumber, setWhatsappNumber] = useState("");

  const handleChange = (e) => {
    setWhatsappNumber(e.target.value);
  };

  return (
    <>

      <div className={styles.formContainer}>
        <h2 className={styles.heading}>
          Consult top sexologists at just{" "}
          <span className={styles.price}>₹199</span>
        </h2>
        <p className={styles.subText}>
          Successfully treated <span className={styles.highlight}>60,000+</span>{" "}
          patients
        </p>
        <label className={styles.label}>
          Enter WhatsApp number to select slot
        </label>

        <div className={styles.inputContainer}>
          <span className={styles.prefix}>+91</span>
          <div className={styles.divider}></div>{" "}
          {/* Divider between country code and icon */}
          <FaWhatsapp className={styles.icon} />
          <input
            type="text"
            className={styles.inputField}
            placeholder="WhatsApp Number*"
            value={whatsappNumber}
            onChange={handleChange}
          />
        </div>
        <OfflineConsultation />
      </div>
      <Spacer height={24}/>
      <BookingSection />
      <Spacer height={24}/>
      <hr className={styles.hrLine}/>
    </>
  );
};

export default ConsultationForm;
