import React from 'react'
import classes from "./PregnancySlotBooking.module.css"
import Consultation from './Consultation/Consultation'

const PregnancySlotBooking = () => {
  return (
    <div className={classes.outerDiv}>
      <div className={classes.container}>
      <Consultation/>
      </div>
      </div>
  )
}

export default PregnancySlotBooking