import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    price :0,
    date : "",
    time : "",
    title : "",
    dropDownOptions : [
      "",
      "I am a working woman",
      "I am a homemaker",
      "I am a working woman but took a break for pregnancy",
    ],
    wa : null
};
const eventSlice = createSlice({
  name: "event",
  initialState: initialState,
  reducers: {
    reset :() => initialState,
   saveEvent(state, actions){
    state = actions.payload
    return state;
   }
  },
});

export const eventActions = eventSlice.actions;
export default eventSlice;
