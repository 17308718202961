import React from "react";
import styles from "./FertilityClinicAppointmentsSummary.module.css";
import { LuStethoscope } from "react-icons/lu";
import { IoLocationOutline } from "react-icons/io5";
import { BiClinic } from "react-icons/bi";
import { LuCalendarDays } from "react-icons/lu";
import { FaRegClock } from "react-icons/fa6";
import { MdOutlineModeEdit } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";
// Sample data simulating an API response
const summaryData = {
  consultationMedium: "Visiting allo clinic",
  location: "Indiranagar",
  doctor: {
    name: "Dr. K Shanti",
    specialization: "Psychiatry",
    experience: "4 yr exp • MBBS, DNB",
  },
  appointment: {
    date: "2024-11-13",
    time: "11:20 AM",
  },
  paymentSummary: {
    items: [
      { name: "Screening Call", originalPrice: 799, discountedPrice: 499 },
      { name: "MRP", price: 799 },
      { name: "Allo discount", discount: -300 },
    ],
    total: 499,
    totalSavings: 300,
  },
  paymentInfo: "₹499 payable at clinic",
  refundPolicy: "If you're not fully satisfied, we'll give you a 100% refund.",
};

const SummaryPage = () => {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.content}>
        {/* Consultation Medium Section */}
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <span>Consultation medium</span>
            <MdOutlineModeEdit className={styles.editIcon} />
          </div>
          <div className={styles.sectionContent}>
            <span>
              <BiClinic className={styles.icon} />
              {summaryData.consultationMedium}
            </span>
          </div>
        </div>

        <hr className={styles.hrTag}/>
        {/* Clinic & Doctor Section */}
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <span>Clinic & Doctor</span>
            <MdOutlineModeEdit className={styles.editIcon} />
          </div>
          <div className={styles.sectionContent}>
            <span>
              <IoLocationOutline className={styles.icon} />
              {summaryData.location}
            </span>

            <span>
              <LuStethoscope className={styles.icon} />
              {summaryData.doctor.name}
            </span>
          </div>
        </div>

        <hr className={styles.hrTag}/>
        {/* Time & Date Section */}
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <span>Time & date</span>
            <MdOutlineModeEdit className={styles.editIcon} />
          </div>
          <div className={styles.sectionContent}>
            <span>
              <LuCalendarDays className={styles.icon} />
              {summaryData.appointment.date}
            </span>
            <span>
              <FaRegClock className={styles.icon} />
              {summaryData.appointment.time}
            </span>
          </div>

        </div>
        <hr className={styles.hrTag}/>
        {/* Payment Summary Section */}
        <div className={styles.section}>
          <div className={styles.sectionHeader}>Payment summary</div>
          {summaryData.paymentSummary.items.map((item, index) => (
            <div className={styles.paymentItem} key={index}>
              <span>{item.name}</span>
              <span>
                {item.originalPrice && (
                  <span className={styles.strikethrough}>
                    ₹{item.originalPrice}
                  </span>
                )}
                {item.discountedPrice || item.price || item.discount}
              </span>
            </div>
          ))}
          <div className={`${styles.paymentItem} ${styles.total}`}>
            <span>Total</span>
            <span>₹{summaryData.paymentSummary.total}</span>
          </div>
        </div>

        {/* Total Savings Notice */}
        <div className={styles.savingsNotice}>
          <span>
             Total savings ₹{summaryData.paymentSummary.totalSavings}
          </span>
        </div>

        {/* Refund Policy Section */}
        <div className={styles.refundPolicy}>
          <div className={styles.refundPolicyTextContainer}>
            <h4>Refund policy</h4>
            <p>{summaryData.refundPolicy}</p>
          </div>
          <img
            className={styles.returnPolicyImg}
            src="https://cdn.imumz.com/pages.imumz/return-policity-fc.png"
            alt="return"
          />
        </div>
      </div>
      {/* Bottom Bar with Payment Information and Next Button */}
      <div className={styles.bottomBar}>
        <div className={styles.paymentInfo}>{summaryData.paymentInfo}</div>
        <button className={styles.nextButton}>Next</button>
      </div>
    </div>
  );
};

export default SummaryPage;
