import { createSlice } from "@reduxjs/toolkit";

// [
//   { text1: "Today", text2: "10 May" },
//   { text1: "Tomorrow", text2: "11 May" },
//   { text1: "Thursday", text2: "12 May" },
//   { text1: "Friday", text2: "13 May" },
// ]

// [
//   { text1: "AM", text2: "10:30" },
//   { text1: "AM", text2: "11:00" },
//   { text1: "PM", text2: "12:30" },
//   { text1: "PM", text2: "01:30" },
// ]

const initialState = {
  timeOptions: [],
  dateOptions: [],
  timeOptionsForTheSelectedDay: [],
  newDate: {},
  newTime: {}
};

const slotsSlice = createSlice({
  name: "slots",
  initialState: initialState,
  reducers: {
    reset :() => initialState,
    setTimeOptions(state, actions) {
      state.timeOptions = actions.payload;
    },
    setDateOptions(state, actions) {
      state.dateOptions = actions.payload;
    },
    setNewDate(state, action) {
      const newDate = action.payload
      state.newDate = newDate;
      state.timeOptionsForTheSelectedDay = state.timeOptions[newDate.id]

    },
    setTimeOptionsForTheSelectedDay(state, action) {
      state.timeOptionsForTheSelectedDay = action.payload;
    },
    setNewTime(state, actions){
      state.newTime = actions.payload;
    }
  },
});

export default slotsSlice;

export const slotsActions = slotsSlice.actions;
