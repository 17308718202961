import React from 'react';
import styles from './PrinciplesSection.module.css';

const principles = [
  {
    title: 'We always work to improve your natural fertility',
    description: 'Human bodies are magical. When given the right support, you can see great results.',
    imageUrl: 'https://cdn.imumz.com/pages.imumz/natural-ferlitly-principle.png',
  },
  {
    title: 'We always keep IVF as the last resort',
    description: 'IVF has been recommended too soon for many couples. We encourage natural methods and believe in their potential.',
    imageUrl: 'https://cdn.imumz.com/pages.imumz/last-option-principle.png',
  },
  {
    title: 'We always give you options & help you take an informed decision',
    description: 'We are here to guide you. Know all your options before making a decision.',
    imageUrl: 'https://cdn.imumz.com/pages.imumz/good-habit-principle.png',
  },
  // Add more items as needed
];

const PrinciplesSection = () => {
    return (
      <div className={styles.sectionContainer}>
        <h2 className={styles.sectionTitle}>What principles we believe in</h2>
        <div className={styles.carousel}>
          {principles.map((principle, index) => (
            <div key={index} className={styles.card}>
              <img src={principle.imageUrl} alt="" className={styles.cardImage} />
              <div className={styles.cardContent}>
                <h3 className={styles.cardTitle}>{principle.title}</h3>
                <p className={styles.cardDescription}>{principle.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default PrinciplesSection;