import React from 'react'
import classes from "./FertilityClinicAppointments.module.css"
import Consultation from './Consultation/Consultation'
import SummaryPage from '../FertilityClinicAppointmentsSummary/FertilityClinicAppointmentsSummary'
const FertilityClinicAppointments = () => {
  return (
    <div className={classes.outerDiv}>
      <div className={classes.container}>
      {/* <Consultation/> */}
      <SummaryPage/>
      </div>
      </div>
  )
}

export default FertilityClinicAppointments