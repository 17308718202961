// Header.tsx
import React from 'react';
import styles from './ScheduleConsultation.module.css';

const Header = () => (
  <header className={styles.header}>
    <button className={styles.backButton}>{"<"}</button>
    <h1 className={styles.title}>Schedule Consultation</h1>
  </header>
);

export default Header;
